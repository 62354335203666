*,
html {
  margin: 0px;
  padding: 0px;
  /* box-sizing: border-box; */
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

/* home banner css - start */

.cd-login-button {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  font-family: system-ui;
  text-align: right;
}
.cd-login-button a {
  padding: 8px 15px;
}
.cd-banner-content {
  height: 100vh;
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
  /* background: url("../public/banner-img.jpg") center center/cover no-repeat; */
}
.cd-banner-item {
  float: left;
}
.cd-left-content {
  width: 39%;
  border-right: 1px solid green;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cd-hide-cms-head {
  opacity: 0;
  transition: all;
  transform: translateY(50px) rotate(10deg);
  font-size: 40px;
}

.cd-show-cms-head {
  opacity: 1;
  transition: all;
  transition-duration: 1s;
  transform: translateY(0px) rotate(0deg);
  font-size: 40px;
}
.cd-logo-img {
  padding: 20px;
  background: white;
  border-radius: 10%;
}
.cd-logo-img img {
  border-radius: 10%;
}
.cd-project-head {
  margin-top: 25px;
}

.cd-right-content span {
  color: #f35b07;
}

.cd-highlight-text {
  color: #f35b07;
}

.cd-project-head h2 span {
  color: #f35b07;
}
.cd-right-content {
  width: 59%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* home banner css - end */

/* close commented backslash hack */

.cd_heading_2_container {
  margin: 5px 0px;
}

.cd_card_container {
  text-align: center;
  margin-top: 10px;
}

.cd_card {
  display: inline-block;
  max-width: 30%;
  border: 1px solid #bee1ff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 3px 3px #d1e9ff;
}

.cd-input-container {
  margin-bottom: 10px;
}

.cd-eye-button-parent {
  position: relative;
}

.cd-eye-button-img {
  position: absolute;
  cursor: pointer;
  padding: 3px;
  height: 15px;
  bottom: 10px;
  right: 20px;
}

.cd-input-label {
  text-align: left;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.cd-checkbox-label {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0;
}

.cd-input-field {
  padding: 10px;
  font-size: 16px;
}

.cd-btn-container {
  margin-top: 10px;
}

.cd-btn {
  padding: 5px 15px;
  font-size: 15px;
  cursor: pointer;
}

.cd-msg-container {
  margin: 5px 0px;
}

.cd-err-msg {
  color: red;
}

.cd-success-msg {
  color: #008000;
  /*Green*/
}

.cd-common-anchor-tag {
  text-decoration: none;
  display: inline-block;
}

.cd-common-anchor-tag > :first-child {
  /* color: white; */
}

.cd_loader {
  border: 5px solid #b6b2b2;
  border-top: 5px solid green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation:
    spin 1s linear infinite,
    appear 0.2s ease-out forwards;
  margin: 10px auto;
}

.not-appeared {
  opacity: 0;
  transition: opacity 0.2s;
}

.appeared {
  opacity: 1;
  transition: opacity 0.2s;
}

.appeared-top-bar {
  opacity: 1;
  transition: opacity 0.8s;
  position: relative;
}
.not-appeared-top-bar {
  opacity: 0;
  transition: opacity 0.8s;
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cd-user-profile-photo-container {
  margin: 10px 0px;
}

.cd-user-profile-photo-container a {
  display: block;
}

.cd-user-profile-photo {
  height: 150px;
  max-width: 150px;
  /* width: 150px; */
  border: 2px solid black;
  border-radius: 50%;
}

.cd-pagination-container {
  text-align: center;
}

.cd-pagination-wrapper {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px 0px;
  background-color: rgba(204, 204, 204, 0.288);
  border-radius: 10px;
}

.cd-pagination-wrapper > :last-child {
  margin-right: 0px;
}

.cd-pagination-element {
  float: left;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 10px;
  margin-right: 10px;
}

.cd-go-to-page-container {
  text-align: center;
}

.cd-go-to-page-wrapper {
  display: inline-block;
  padding: 10px 10px;
  background-color: rgba(204, 204, 204, 0.288);
  border-radius: 10px;
}

.cd-task-list-table td,
.cd-task-list-table th {
  padding: 5px 15px;
  text-wrap: nowrap;
}

.cd-connection-off-msg {
  animation: connection-lost-msg 1s ease-out forwards;
}
@keyframes connection-lost-msg {
  0% {
    opacity: 0;
    padding: 0px;
    height: 0;
    background-color: red;
    color: white;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    padding: 10px;
    height: auto;
    background-color: red;
    color: white;
  }
}
.cd-connection-on-msg {
  animation: connection-back-msg 1s ease-out forwards;
}
@keyframes connection-back-msg {
  0% {
    opacity: 0;
    padding: 0px;
    height: 0;
    background-color: green;
    color: white;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    padding: 10px;
    height: auto;
    background-color: green;
    color: white;
  }
}

.cd-button-for-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .cd-button-for-mobile {
    display: block;
  }
  .cd-left-content {
    width: 100%;
  }
  .cd-right-content {
    width: 100%;
  }
}
