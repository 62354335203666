.cd-subscriptins-list-container {
  margin: 5px 0px;
}

.cd-subscriptins-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-subscription-list-element {
  margin-bottom: 10px;
}
