.cd_dashboard_left_section {
  /* background-color: #001c2b; */
  float: left;
  /* color: #ffff; */
  width: 12%;
  height: 100vh;
  border-right: 1px solid #000000;
}

.cd_dashboard_right_section {
  float: right;
  width: 87%;
}

.cd_dashboard_content {
  max-height: 89vh;
  overflow-y: auto;
}

.cd-menu-item {
  /* margin-bottom: 15px; */
  padding: 12px 15px;
}

.cd-organisation-strip-controls .cd-menu-item {
  padding: 12px 0px;
  text-align: center;
}

.cd-active {
  background-color: #195600;
}

.cd-active > :first-child {
  color: white;
}

.cd-task-list-container {
  margin: 5px 0px;
}

.cd-task-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-task-list-element {
  margin-bottom: 10px;
}

.cd-drive-conn-btn {
  float: left;
  margin-right: 10px;
}

.cd-drive-connection-container .cd-err-msg {
  cursor: pointer;
  float: left;
  border: 1px solid red;
  border-radius: 3px;
  padding: 3px 10px;
}

.cd-drive-connection-container .cd-err-msg:hover {
  background-color: rgb(255, 57, 57);
  color: white;
}

.cd-organization-slug-info {
  cursor: pointer;
  margin-left: 3px;
}

.cd-organization-slug-info:hover + .cd-organization-info-msg {
  opacity: 1;
}

.cd-organization-info-msg {
  opacity: 0;
  margin: 0;
  border: 2px solid #f65b04;
  padding: 5px;
  border-radius: 5px;
  top: -17%;
  left: 4.5%;
  width: 30%;
  font-size: 12px;
  text-align: center;
  position: absolute;
  background: white;
  transition: opacity 0.5s ease;
}
