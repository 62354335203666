.cd_navbar_container {
  text-align: right;
}

.cd_navbar_wrapper {
  /* display: block; */
  padding-right: 10px;
  padding-top: 10px;
  float: right;
  width: 35%;
}

.cd_navbar_element_group {
  list-style: none;
  float: left;
}

.cd_navbar_element_group > :last-child {
  margin-right: 0px;
}

.cd_navbar_element > :first-child {
  margin: 0;
  padding: 0;
}

.cd_navbar_element {
  float: left;
  margin-right: 30px;
}

.cd_navbar_user_profile_controls {
  float: right;
}

.cd-bell-icon-container img {
  height: 30px;
  cursor: pointer;
  border-radius: 10px;
  /* padding: 5px; */
  border: 1px solid black;
}

.cd_user_control_section {
  width: 120px;
  text-align: left;
  /* position: relative; */
}

.cd_user_control_btn {
  background-color: #ffff;
  /*white*/
  border: 0;
  cursor: pointer;
}

.user_icon {
  border: 1px solid black;
  border-radius: 50%;
  height: 35px;
  max-width: 35px;
}

.cd_user_controls {
  margin-top: 5px;
  text-align: left;
  border-radius: 9px;
  position: absolute;
}

.cd_user_control_element {
  text-decoration: none;
  color: black;
  text-wrap: nowrap;
  display: block;
  /* color: #000000; */
  cursor: pointer;
}
