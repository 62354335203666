.cd-paypal-checkout-container {
  margin-top: 15px;
}

.cd-product-price {
  margin-bottom: 5px;
}

.cd-product-tax-container {
  margin-bottom: 5px;
}

/* .cd-product-tax-list ul{
    list-style: none;
} */

/* .cd-product-coupon{
    margin-bottom: 5px;
} */

.cd-product-final-price {
  margin-top: 5px;
  font-weight: 600;
}

.cd-price-value {
  font-weight: 600;
}

.cd-paypal-button-container {
  margin-top: 15px;
  width: 50%;
  padding: 10px 0px;
  /* border: 1px solid #d0d4e4; */
}

.cd-stripe-checkout-container {
  margin-top: 10px;
}

.stripe-payment-container {
  width: 70%;
  margin-top: 10px;
  padding: 10px 5px;
}
