.cd-projects-list-container {
  margin: 5px 0px;
}

.cd-projects-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-project-list-element {
  margin-bottom: 10px;
}

.cd-project-options-container {
  /* margin: 5px 0px; */
}

.cd-project-option {
  float: left;
  margin-right: 15px;
}

.cd-task-group-list-element {
  margin-bottom: 10px;
}

.cd-task-group-list-element:last-child {
  margin-bottom: 0px;
}

.cd-tag-list-container {
  margin: 5px 0px;
}

.cd-tag-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-tag-list-element {
  margin-bottom: 10px;
  padding: 5px 0px;
}

.cd-tag-list-element .cd-tag-title {
  padding: 4px 10px;
  border-radius: 5px;
  text-wrap: nowrap;
  font-size: 16px;
}

.cd-edit-tag-icon {
  /* margin-left: 5px; */
  cursor: pointer;
}

.cd-edit-tag-icon img {
  padding: 0px 5px;
  border-radius: 5px;
}

/* .cd-edit-tag-icon img[aria-expanded='true']{
  rotate: 180deg;
} */

.cd-edit-tag-icon img:hover {
  background-color: #f3f3f3;
}

.cd-view-comments .cd-comment-icon {
  cursor: pointer;
}
.cd-edit-tag-icon img {
  transition: rotate 0.5s;
}
.cd-edit-tag-icon img[aria-expanded="true"] {
  rotate: 180deg;
  transition: rotate 0.5s;
}
.cd-task-aria-expanded {
  background-color: #cccccc49;
}

.cd-task-media-image-thumb {
  height: 25px;
}

.cd-subtask-row {
  /* margin:19px 0px 19px 30px; */
}

.cd-subtask-row .cd-task-list-cell {
  background-color: #cccccc49;
}

.expanded {
  animation: expand 0.2s ease-in;
}

.cd-task-table-heads-container .cd-task-title {
  width: 300px;
  text-align: left;
  padding-left: 10px;
}

.cd-task-table-cell-row .cd-task-title-cell {
  width: 300px;
  text-align: left;
  padding-left: 10px;
}

@keyframes expand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cd-comment-container {
  border-bottom: 1px solid rgb(208, 212, 228);
  padding: 5px;
}

.cd-comment-creator-section > :last-child {
  border-bottom: none;
}

.cd-creator-name {
  margin-top: 6px;
  margin-left: 5px;
}

.cd-comment-reply {
  margin-bottom: 5px;
}

.cd-comment-creator-section {
  border: 1px solid rgb(208, 212, 228);
  /* border-radius: 15px; */
  margin-bottom: 10px;
  padding: 6px 12px;
}

.cd-cross-mark {
  /* font-size: 20px; */
  font-weight: bold;
}

.cd-cross-mark:hover {
  /* color: white; */
  cursor: pointer;
}

.cd-comment-creator-info {
  /* padding: 10px 15px; */
}

.cd-comment-content {
  /* padding: 5px 15px; */
}

.cd-dropdown-icon {
  height: 20px;
}

.cd-load-comments {
  margin-bottom: 10px;
}

.cd-comment-actions {
  /* border-top: 1px solid rgb(208, 212, 228); */
  padding-bottom: 8px;
}

.cd-comment-creator-profile,
.cd-creator-name {
  float: left;
}

.cd-comment-time {
  float: right;
}

.cd-comment-time span,
.cd-reply-time span {
  font-size: 13px;
  color: rgb(103, 104, 121);
}

.cd-replay-btn {
  cursor: pointer;
}

.cd-reply-list {
  border-top: 1px solid rgb(208, 212, 228);
  padding-top: 5px;
}

.cd-reply-content {
  padding: 5px;
}

.cd-reply-creator-profile {
  float: left;
  width: 7%;
  margin-right: 15px;
}

.cd-reply-text-box {
  float: left;
}

.cd-reply-text {
  max-width: 89%;
  background-color: rgb(246 247 251);
  padding: 10px;
  border-radius: 8px;
  min-width: 25%;
}

.cd-tag-title {
  padding: 1px 2px;
  border-radius: 5px;
  text-wrap: nowrap;
  font-size: 12px;
}

.cd-project-users-list-container {
  margin-top: 15px;
}

.cd-project-users-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-project-user-list-element {
  margin-bottom: 10px;
}

.cd-task-group-input {
  float: left;
  margin-right: 15px;
}

.cd-add-task-group-button {
  float: left;
  margin-top: 20px;
}

.cd-img-thumb-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

img.cd_uploaded_image_thumb {
  height: 8em;
  border: 1px solid black;
  margin-right: 10px;
  border-radius: 5px;
}

.cd-img-full-preview {
  margin-top: 20px;
  width: 95%;
}

.cd-task-table-container {
}

.cd-task-table-wrapper {
  position: relative;
}

.cd-task-table-heads-container {
  position: absolute;
  top: 0;
  background-color: #ffff;
  border-top-left-radius: 5px;
  border-top: 1px solid #d0d4e4;
  border-left: 5px solid #f35b07;
}

.cd-task-table-head {
  float: left;
  width: 140px;
  text-align: center;
  padding: 5px 0px;
  /* height: 30px; */
  /* max-height: 30px; */
  /* overflow-y: auto; */
  border-right: 1px solid #d0d4e4;
  border-bottom: 1px solid #d0d4e4;
  font-weight: 500;
}

.cd-task-table-cells-container {
  padding-top: 33px;
  border-radius: 5px;
  border-left: 5px solid #f35b07;
}

.cd-task-table-cell-row {
  /* border-bottom: 1px solid #d0d4e4; */
}

.cd-task-list-cell {
  float: left;
  width: 140px;
  text-align: center;
  height: 30px;
  max-height: 30px;
  overflow-y: auto;
  padding: 5px 0px;
  border-right: 1px solid #d0d4e4;
  border-bottom: 1px solid #d0d4e4;
}

.cd-task-title-cell {
  transition: opacity 0.5s ease-out;
}

.cd-task-title-cell:hover .cd-add-subtask-button {
  opacity: 1;
}

.cd-add-subtask-button {
  opacity: 0;
  text-align: center;
  /* background: #f3f3f3; */
  border-radius: 10px;
  padding-left: 5px;
  /* border: 1px solid #d0d4e4; */
  cursor: pointer;
  transition: opacity 0.5s ease-out;
}

.cd-task-list-cell .cd_modal {
  text-align: left;
}

.cd-task-list-cell .cd-task-cell-control {
  display: inline-block;
}
