.cd_organisation_left_section {
  float: left;
  width: 6%;
  height: 100vh;
  border-right: 1px solid #000000;
  animation: appear 1s ease-in forwards;
  overflow-y: auto;
}

@keyframes appear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.cd_organisation_right_section {
  float: right;
  width: 92%;
}

.cd-organisations-list-element {
  margin-bottom: 15px;
}

.cd-organisations-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-user-profile-thumbnail {
  height: 50px;
  border: 2px solid black;
  border-radius: 50%;
}

.cd-organisations-user-list-element {
  margin-bottom: 15px;
}

.cd-organisations-user-list-container {
  margin: 10px 0px;
}

.cd-organisations-user-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-organisation-icon {
  height: 50px;
  border-radius: 5px;
}

.cd-active-organisation {
  background-color: #c5c5c5;
  pointer-events: none;
}

.cd-active-organisation-icon {
  /* border: 1px solid black; */
  transition-property: all;
  transition-duration: 0.2s;
}
