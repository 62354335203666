.cd-notifications-container > :last-child {
  margin-bottom: 0px;
}

.cd-notification-list-element {
  margin-bottom: 10px;
}

.cd-notification-type {
  display: inline;
  font-size: 10px;
}

.cd-notification-type .cd-cross-mark {
  margin-left: 5px;
}

.cd-notification-title {
  font-weight: 500;
  font-size: 18px;
}

.cd-notification-description {
  font-size: 15px;
}
