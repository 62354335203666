.cd-loader-2 {
  border: 7px solid;
  position: absolute;
  top: 2px;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35)
    rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}
@keyframes animloader {
  0% {
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75);
  }

  33% {
    border-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35);
  }

  66% {
    border-color: rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  }

  100% {
    border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15);
  }
}
